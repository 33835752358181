@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../../../../../../../shared/scss/dt-base';

.line {
  align-items: center;
  display: inline-flex;
  flex-grow: 1;
  height: 35px;
  justify-content: space-between;
}

.description {
  font-size: rem(15);
  font-weight: 900;
}

.description-with-label {
  @extend .description;
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
}

.label {
  color: $medium-gray;
  font-size: rem(9);
  font-weight: normal;
  text-transform: uppercase;
}

.wheel-size {
  border-left: 1px solid $medium-light-gray;
  display: inline-flex;
  flex-direction: column;
  height: 27px;
  padding-left: 9px;

  > :first-child {
    font-size: rem(16);
    font-weight: 900;
  }

  > :last-child {
    color: $medium-gray;
    font-size: rem(9);
  }
}
