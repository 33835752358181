@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../../../shared/scss/_dt-base';

.staggered-front {
  display: block;
  margin: 5px 0 14px;
}

.staggered-options {
  margin-bottom: -10px;
}

.staggered-rear {
  display: block;
  margin: 5px 0 7px;
}

.fitment-tire-size {
  & > h5 {
    position: relative;
    padding-bottom: 30px;
    border-bottom: 1px solid $dt-medium-light-gray;

    @include media($min-sm) {
      border: 0;
    }
  }

  & :global(.fitment-step-container__more-info-button) {
    position: absolute;
    bottom: 10px;
    right: 10px;

    @include media($min-sm) {
      position: static;
      flex: 0 0 auto;
    }
  }
}
