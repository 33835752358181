@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../shared/scss/_dt-base';

$shadow: #555;

.nav {
  background: $light-gray;
  border-radius: 5px 5px 0 0;
  border-left: 1px solid $dt-medium-light-gray;
  border-right: 1px solid $dt-medium-light-gray;
  border-top: 1px solid $dt-medium-light-gray;
  display: inline-flex;
  margin-bottom: -1px;
  max-width: 100%;

  &:after {
    background-color: $light-gray;
    bottom: -5px;
    content: '';
    position: absolute;
    height: 15px;
    left: -1px;
    width: 1px;
  }
}

.label {
  align-items: center;
  color: $white;
  display: flex;
  font-size: rem(13);
  font-weight: 700;
  justify-content: center;
  max-width: 97px;
  padding: 2px 20px 0px;
  text-transform: uppercase;
  white-space: nowrap;

  @include media($min-sm) {
    flex-grow: 0;
    padding: 2px 25px 0px;
  }
}
