@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/_dt-base';

.home-hero-container {
  width: 100%;
  background: $dt-light-medium-gray;
  height: 46.7vw;
  max-height: 70px;

  @include media($min-sm) {
    height: 33.333vw;
    max-height: 105px;
  }

  @include media($min-md) {
    max-height: 128px;
  }

  @include media($min-lg) {
    max-height: 210px;
  }
}

.fitment-section.fitment-section {
  margin-top: -20px;
  padding-bottom: 20px;
  background: $dt-light-medium-gray;

  @include media($min-sm) {
    padding-bottom: 30px;
  }

  @include media($min-md) {
    padding-bottom: 40px;
  }

  @include media($min-xl) {
    padding-bottom: 50px;
  }
}

.fitment-container {
  position: relative;
  margin: -50px auto 0;
  max-width: 1330px;

  @include media($min-sm) {
    margin-top: -70px;
  }

  @include media($min-md) {
    margin-top: -86px;
  }

  @include media($min-lg) {
    margin-top: -170px;
  }
}

.image-cta {
  margin: 60px auto;
  display: block;
}

.image-cta-placeholder {
  width: 100%;
  min-height: 380px;
  background: $light-gray;
}

.promo-message {
  margin-top: 35px;
  display: block;
  text-align: center;
  font-size: rem(18);

  a {
    color: $dt-red;
  }
}

.actions {
  margin-bottom: 50px;
  text-align: center;
}

.cms-sections {
  section:global(.Section1E) {
    background: $dt-light-medium-gray;
    padding-bottom: 20px;
  }

  div:global(.Section2) {
    position: relative;
    :global .submit-promotions-rebate {
      position: absolute;
      top: -38px;
      right: 0;

      @include media($min-md) {
        top: -48px;
      }
    }

    :global .cms-html-content:last-child {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 25px;

      @include media($min-sm) {
        margin-top: 20px;
      }

      @include media($min-xl) {
        margin-top: 30px;
      }

      a,
      button {
        width: 100%;
      }
    }
  }
}
