@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../../../../../shared/scss/dt-base';

.size-option {
  background-color: $white;
  border: 1px solid $medium-light-gray;
  border-radius: 3px;
  display: inline-flex;
  margin: 0 13px 15px 0;
  padding: 0 20px;
  width: 209px;
}

.size-option-selected {
  @extend .size-option;
  border-color: $dt-blue;
  border-width: 2px;
  margin: -1px 12px 14px -1px;
  width: 211px;
}

.size-option-staggered {
  @extend .size-option;
  flex-wrap: wrap;
  padding-bottom: 8px;
  padding-top: 8px;

  > :first-child {
    border-bottom: 1px solid $medium-light-gray;
    margin-bottom: 4px;
    padding-bottom: 4px;
  }
}

.size-option-staggered-selected {
  @extend .size-option-staggered;
  @extend .size-option-selected;
}
