@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../shared/scss/_dt-base';

.container {
  line-height: 0.9;

  button {
    background: none;
    color: $medium-light-gray;
    cursor: default;
    font-size: rem(14);
    font-weight: 500;
    line-height: 0.9;
    padding: 0 12px 0;
    position: relative;
    transition: color 0.3s;
    user-select: none;

    &:first-child {
      padding-left: 0;
    }

    &:not(:last-child) {
      &::after {
        background: $dt-medium-gray;
        bottom: 0;
        content: '';
        height: 14px;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
      }
    }
  }

  .is-active {
    color: $dt-blue;
    cursor: pointer;
    &:hover,
    &:focus {
      color: lighten($dt-blue, 10%);
    }
  }

  .is-selected {
    color: $dt-black;
    cursor: pointer;
    font-weight: bold;
  }
}
