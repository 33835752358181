@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/_dt-base';

.label {
  pointer-events: none;
}

.container {
  :global {
    .input-container {
      border-width: 0;
      overflow: visible;
    }

    .input-container--info .react-select__control {
      border-color: $blue-700;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .select-toggle {
      pointer-events: none;
    }

    .react-select {
      height: 100%;
      border: none;
    }

    .react-select__control {
      display: flex;
      height: 100%;
      border: 1px solid $grey-500;
      border-radius: 4px;
      outline: none;
      box-shadow: none;

      &:hover {
        border-color: $grey-500;
      }

      &--is-focused,
      &--is-focused:hover {
        border-color: $gold-500;
      }

      &--menu-is-open {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }

    .react-select__value-container {
      align-items: center;
      display: grid;
      flex: 1;
      padding: 0;
      position: relative;
      overflow: hidden;
    }

    .react-select__input-container {
      padding-left: 22px;
      grid-area: 1/1/2/3;
    }

    .react-select__single-value {
      grid-area: 1/1/2/3;
      max-width: 100%;
      padding-left: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $black;
      box-sizing: border-box;
    }

    .react-select__indicator-separator {
      display: none;
    }

    .react-select__indicators {
      display: flex;
      align-items: center;
      width: 42px;
      font-size: rem(24);
      color: $grey-500;
      cursor: pointer;

      svg {
        margin-left: 7px;
      }
    }

    .react-select__menu {
      margin: 0;
      box-shadow: none;
      border: 1px solid $grey-500;
      border-top-color: transparent;
      border-radius: 0px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      z-index: 3;
    }

    .react-select__menu-list {
      padding: 0;
      max-height: 210px;

      > div {
        cursor: pointer;

        :hover {
          color: $blue-700;
        }
      }
    }

    .react-select__menu-notice--no-options {
      padding: 10px 22px;
      text-align: left;
      color: $black;
    }

    .react-select__option {
      padding: 9px 24px;
      color: $black;

      &:active,
      &:focus,
      &--is-focused,
      &--is-selected {
        background: transparent;
        color: $blue-700;
      }

      &--is-disabled {
        background-color: $grey-100;
        color: $grey-500;
      }
    }
  }
}

.container--menu-top {
  :global {
    .react-select__menu {
      border-top-color: $grey-500;
      border-bottom-color: transparent;
      border-radius: 0px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .react-select__control {
      &--menu-is-open {
        border-radius: 4px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
    }
  }
}
