@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_HISTORY_RESTRICT_REFERENCE_ACTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../shared/scss/_dt-base';

%container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 -20px;

  @include media($min-md) {
    margin: 0 -40px;
  }

  @include media($min-xl) {
    margin: 0;
  }
}

.container {
  @extend %container;
}

.container-w-divider {
  @extend %container;
  border-top: 10px solid $light-gray;
}

.heading {
  margin: 25px 0 10px;
  padding: 5px 0 7px;
  width: calc(100% - 40px);
  max-width: 1600px;
  color: $black;
  text-transform: capitalize;
  border-bottom: 1px solid $grey-200;

  @include media($min-sm) {
    margin-top: 34px;
  }

  @include media($min-md) {
    margin: 34px 0 18px;
    width: calc(100% - 80px);
  }
}

.intro {
  margin: 0 auto 20px 20px;

  @include media($min-md) {
    margin: 0 auto 25px 40px;
  }
}

.content {
  width: calc(100% - 40px);
  max-width: 1600px;

  @include media($min-md) {
    width: calc(100% - 80px);
  }
}

.content-fullwidth {
  width: 100%;
}
